
export default class ContactObject {

    group_id: number;
    fullname: string;
    email: string;
    tel: string;
    subject: string;
    comment: string;

    constructor(fullname = null, email = null, tel = null, subject = null, comment = null, group_id = null){
        this.group_id = group_id;
        this.fullname = fullname;
        this.email = email;
        this.tel = tel;
        this.subject = subject;
        this.comment = comment;
    }

    clear(){
        this.group_id = null;
        this.fullname = null;
        this.email = null;
        this.tel = null;
        this.subject = null;
        this.comment = null;
    }

    validate(){
        let blnValid = true;

        if(this.group_id === null || this.fullname === null || this.email === null || this.tel === null || this.subject === null || this.comment === null){
            blnValid = false;
        }
     
        return blnValid;
    }

}