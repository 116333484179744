import { Component, OnInit } from '@angular/core';
import { CrmService } from '../crm.service';
import {GroupResponse} from './interfaces/groupResponse';
import ContactObject from './objects/contact';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  endpoint: 'http://crm.localhost/';
  groups: any[];
  blnSuccess: boolean;
  blnSending: boolean = false;
  blnContactValid: boolean = true;
  inboxEmail: string = null;

  //Two way binding
  contact = new ContactObject();

  constructor(private crmService: CrmService) { }

  ngOnInit() {

    this.crmService.fetchGroups().subscribe((data: GroupResponse) => {

      if (data.status !== undefined && data.status === 'success') {
        this.groups = data.message;
      } else {
        console.log(data);
      }

    });

  }

  onSubmit(form) {
    this.blnContactValid = this.contact.validate();

    if(this.blnContactValid){

      this.blnSending = true;
      let status = this.crmService.submitContact(this.contact);
 
      this.blnSuccess = true;
      
      if (this.blnSuccess){
        form.reset();
        console.log(this.contact);

        this.blnSending = false;
     
        this.inboxEmail = this.contact.email;

        setTimeout(() => {
          this.blnSuccess = false;
          this.inboxEmail = null;
        }, 10000);
      }
      else{
        this.blnSending = false;
      }

      
    }
  }

}
