import { Component } from '@angular/core';
import { observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  Navigation = '';

  navigate = (nav)=>{
    
    switch (nav) {
      case "Home":
        this.Navigation = nav;
        break;
      case "Services":
        this.Navigation = nav;
        break;
      case "About":
        this.Navigation = nav;
        break;
      case "Contact":
        this.Navigation = nav;
        break;
      case "VR":
        this.Navigation = nav;
      default:
        this.Navigation = 'Home';
        break;
    }
  }
}
