import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { GroupResponse, ContactObject } from './contact/interfaces/groupResponse';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  endpoint = 'https://crm.idevsystems.co.za/';
  constructor(private http: HttpClient) { }

  public fetchGroups() {
    return this.http.get( this.endpoint + 'api/web/contact_groups');

  }

  public submitContact(Obj: any) {

    this.http.post(this.endpoint + 'api/web/message', Obj)
      .subscribe((data: GroupResponse)=>{
      
        if(data.status != undefined && data.status === 'success'){
          console.log('Contact submitted.');
        }
      },
        error => console.log(error)
    );


  }
}
