import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Get Components for pages
import {HomeComponent} from './home/home.component';
import {ProjectionComponent} from './services/projection/projection.component';
import { CateringComponent } from './services/catering/catering.component';
import { SignageComponent } from './services/signage/signage.component';
import { AuditoriumComponent } from './services/auditorium/auditorium.component';
import { LightingComponent } from './services/lighting/lighting.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import {VirtualRealityComponent} from './services/virtual-reality/virtual-reality.component';
import {ProfessionalServicesComponent} from './services/professional-services/professional-services.component';

const routes: Routes = [
  {
    path:'home',
    component: HomeComponent
  },
  {
    path:'projection',
    component: ProjectionComponent
  },
  {
    path:'catering',
    component: CateringComponent
  },
  {
    path:'signage',
    component: SignageComponent
  },
  {
    path:'auditorium',
    component: AuditoriumComponent
  },
  {
    path: 'lighting',
    component: LightingComponent
  },
  {
    path:'about',
    component: AboutComponent
  },
  {
    path:'contact',
    component: ContactComponent
  },
  {
    path:'virtual-reality',
    component: VirtualRealityComponent
  },
  {
    path:'professional-services',
    component: ProfessionalServicesComponent
  },
  {
    path:'',
    pathMatch:'prefix',
    redirectTo:'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
