import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule  } from '@angular/forms';
// import { InViewportModule } from '@thisissoon/angular-inviewport';
// import { ScrollSpyModule } from '@thisissoon/angular-scrollspy';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProjectionComponent } from './services/projection/projection.component';
import { CateringComponent } from './services/catering/catering.component';
import { SignageComponent } from './services/signage/signage.component';
import { AuditoriumComponent } from './services/auditorium/auditorium.component';
import { LightingComponent } from './services/lighting/lighting.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { VirtualRealityComponent } from './services/virtual-reality/virtual-reality.component';
import { ProfessionalServicesComponent } from './services/professional-services/professional-services.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectionComponent,
    CateringComponent,
    SignageComponent,
    AuditoriumComponent,
    LightingComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    VirtualRealityComponent,
    ProfessionalServicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,

    // InViewportModule,
    // ScrollSpyModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
