import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-virtual-reality',
  templateUrl: './virtual-reality.component.html',
  styleUrls: ['./virtual-reality.component.css']
})
export class VirtualRealityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
